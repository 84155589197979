<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="7">
        <v-card class="credit-card-wrapper px-12" width="685" style="margin: 0 auto">
          <v-card-text>
            <v-alert
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
              class="my-3"
            >
              <p class="subtitle-2 font-weight-light grey--text text--darken-2 ma-0">
                <span class="red--text font-weight-bold">Нагадуємо!</span> Pay24.net.ua не володіє інформацією про ваші картки, їхні номери, термімн дії і cvv-код. Картка реєструється на сайті Таскомбанку, банк у відповідь надсилає лише представлення картки, вигляду: 123456789ХХХХ9876
              </p>
            </v-alert>
          </v-card-text>
          <v-card-text class="py-1 my-0" v-if="cards.length">
            <div class="pl-2">
              <v-switch v-model="showHidden" hide-details color="success" inset style="padding-left: 3px" :class="`${$vuetify.breakpoint.mdAndUp ? 'pb-2' : 'pb-4'}`">
                <template v-slot:label>
                  <div>{{ showHiddenLabelText }}<br v-if="$vuetify.breakpoint.xsOnly"><strong class="green--text">{{ showHiddenLabelCounter }}</strong></div>
                </template>
              </v-switch>
            </div>
          </v-card-text>
          <v-card-text class="my-2">
            <v-row align="center" justify="space-between" dense class="card-row">
              <v-col cols="12" md="6" sm="6" xs="12" v-for="(card) in filteredCards" :key="`${card.id}`" class="card-col">
                <cart-item @selectCard='selectCard' :card="card" :currentCard="currentCard" @hideCard="hideCard" :selectEvent="false"/>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" class="card-col">
                <v-card class="credit-card no-select mb-2 card-button"
                        min-height="120px"
                        width="240px"
                        @click.stop="addNewCard"
                >
                  <div class="button-title-wrapper">
                    <span class="text-uppercase">Ввести<br>платіжні реквізити</span>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <!--          <v-card-text class="text-right pb-8" v-show="cards.length">-->
          <!--            <v-btn depressed color="success" :disabled="!currentCard || loading || payments.length === 0" @click.stop="payment">Підтвердити оплату</v-btn>-->
          <!--          </v-card-text>-->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CartItem from '@/components/payments/CartItem.vue'
  import { CardAPI } from '@/api/tasCard'
  export default {
    name: "CardList",
    components: {
      CartItem
    },
    data: () => {
      return {
        cards: [],
        currentCard: undefined,
        sum: 0,
        loading: false,
        showHidden: false
      }
    },
    computed: {
      filteredCards () {
        if (this.showHidden) {
          return this.cards.filter(item => !item.removed);
        } else {
          return this.cards.filter(item => !item.hidden && !item.removed);
        }
      },
      hiddenServicesCounter() {
        if (this.cards) {
          return this.cards.filter(item => item.hidden).length;
        } else {
          return 0;
        }
      },
      showHiddenLabelText() {
        const point = this.hiddenServicesCounter ? ',' : '';
        return this.showHidden ? 'Показувати відключені картки' + point : 'Не показувати відключені картки' + point;
      },
      showHiddenLabelCounter() {
        return this.hiddenServicesCounter ? ' відключено: ' + this.hiddenServicesCounter : '';
      }
    },
    methods: {
      selectCard(id) {
        if (id === this.currentCard) {
          this.currentCard = undefined;
        } else  {
          this.currentCard = id;
        }
      },
      async getCardList() {
        try {
          const { data } = await CardAPI.list();
          this.cards = data;
        } catch (error) {
          this.$snackbar("Помилка одержання карток", 'error')
        }
      },
      async addNewCard() {
        try {
          const {data} = await CardAPI.new({ 'acquiring': 'tas', 'payments': [] });
          window.location.href = data;
        } catch (e) {
          this.$snackbar("Відповідь не отримана", 'error')
        }

      },
      hideCard(id) {
        this.cards.forEach(item => {
          if (item.id === id) {
            item.hidden = !item.hidden;
          }
        })
      },
    },
    mounted() {
      this.getCardList();
    }
  }
</script>

<style scoped lang="scss">
  .credit-card {
    background: rgba(0, 0, 0, 0.69);
    position: relative;
    cursor: pointer;
    transition: 0.2s scale ease;
    transform: scale(1);
    filter: none;

  }
  .card-row {
    .card-col:nth-child(even) > .credit-card {
      margin-left: auto;
    }
    .card-col:nth-child(odd) > .credit-card {
      margin-right: auto;
    }
  }
  .no-select {
    user-select: none;
  }
  .card-button {
    position: relative;
    background: #f1f8fe;
    min-height: 120px;
    border: 0.9px solid #c1e6fe;
    box-shadow: none;
    border-radius: 7px;

    .button-title-wrapper {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
      width: 100%;
      text-align: center;
      color: #758692;
      letter-spacing: 1.1px;
      transition: .3s background-color ease;
    }

    &:hover {
      background: #ecf3f9;
      transition: .3s background-color ease;
    }
  }
</style>
