<template>
  <v-hover
    v-slot:default="{ hover }"
    open-delay="200"
  >
    <v-card class="credit-card no-select mb-2"
            :class="selectCardClass()"
            :style="`${cardDescription.background}`"
            min-height="120px"
            width="240px"
            @click.native="selectCard">
      <img :src="`${publicPath}${getCardLogo(cardDescription.service)}`" draggable="false" alt="payLogo"
           style="display: block;" :style="`${cardDescription.right}`" class="pay-logo no-select">
      <div class="card-title">
        <span class="white--text text-uppercase no-select" style="font-size: 0.74em;">
          {{ title }}
        </span>
      </div>
      <div class="card-selected" v-show="currentCard === id">
        Картку обрано
      </div>

      <div class="card-default" v-show="defaultCard">
        За замовчуванням
      </div>
      <div class="card-number">
        <span class="white--text no-select" style="font-size: 1em">{{ pan }}</span>
      </div>
      <confirm-dialog @confirmModal="deleteCart" v-model="deleteModal" :useParentDialog="true" :parentDialog="deleteModal" :showButton="false" :divider="false" :headerColor="'error white--text mb-1'">
        <template v-slot:buttonName>
        </template>
        <template v-slot:header>
          Вилучити картку {{ pan }} ?
        </template>
        <template v-slot:content>Відновити картку буде неможливо!</template>
      </confirm-dialog>

      <div class="text-center card-button-group">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn
              text
              color="transparent"
              small
              v-on="on"
              :disabled="false"
              v-show="!activeCard ? true : hover"
              @click.stop=""
              style="border: none transparent !important;"
            >
                <v-icon v-on="on" color="white" large>
                  mdi-dots-horizontal
                </v-icon>
            </v-btn>
          </template>

          <v-card max-width="360px">
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="primary" large>mdi-card-text-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">Опції картки</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">Відключення та встановлення картки по замовчуванні</v-list-item-subtitle>
                  <v-btn small dark color="red" class="mt-2" @click.stop="deleteModal = true">Вилучити картку</v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="menuDefaultCard" color="primary" :disabled="defaultCard"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Картка за замовчуванням</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="menuActiveCard" color="success"></v-switch>
                </v-list-item-action>
                <v-list-item-title>{{ menuActiveCard ? 'Картка активна' : 'Картка відключена' }}</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="red" @click="menu = false">Відмінити</v-btn>
              <v-btn color="primary" text @click="cardMenu">Підтвердити</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
      <!--      <div class="card-button-group">-->
      <!--        <span v-if="hover"></span>-->
      <!--        <v-btn color="primary" fab x-small dark style="display: block" class="mb-1" height="28px" width="28px">-->
      <!--          <v-icon>mdi-pencil</v-icon>-->
      <!--        </v-btn>-->

      <!--        <v-btn color="primary" fab x-small dark style="display: block" height="28px" width="28px">-->
      <!--          <v-icon>mdi-pencil</v-icon>-->
      <!--        </v-btn>-->
      <!--      </div>-->
    </v-card>
  </v-hover>
</template>

<script>
  import {getPayService, getCardLogo} from '@/utils'
  import { CardAPI } from '@/api/tasCard'
  import confirmDialog from "@/components/global/confirmDialog";
  import ConfirmDialog from "../global/confirmDialog";

  export default {
    name: "CartItem",
    components: {ConfirmDialog},
    // props: ['card', 'currentCard', 'selectEvent'],
    props: {
      card: {
        require: true,
        default() {
          return {}
        }
      },
      currentCard: {
        default() {
          return undefined
        }
      },
      selectEvent: {
        default() {
          return true
        }
      }
    },
    data() {
      return {
        id: this.card.id,
        title: this.card.title,
        pan: this.card.pan,
        hidden: this.card.hidden,
        removed: this.card.removed,
        acquiring_source: this.card.acquiring_source,
        cardDescription: {},
        menu: false,
        menuDefaultCard: this.card.default,
        menuActiveCard: !this.card.hidden,
        defaultCard: this.card.default,
        activeCard: !this.card.hidden,
        deleteModal: false,
      }
    },
    methods: {
      async removeCard() {
        try {
          await CardAPI.remove({ id: this.id });
          this.$snackbar("Картка вилучена успішно", 'success')
        } catch (e) {
          this.$snackbar("Помилка вилучення картки", 'error')
        }
      },
      async hideCard() {
        try {
          await CardAPI.hide({ id: this.id });
        } catch (e) {
          this.$snackbar("Помилка відключення картки", 'error')
        }
      },
      async setDefaultCard() {
        try {
          await CardAPI.setDefault({ id: this.id });
        } catch (e) {
          this.$snackbar("Помилка встановлення картки за замовчуванням", 'error')
        }
      },
      getCardLogo,
      getPayService,
      selectCardClass() {
        if (this.selectEvent) {
          let cardClass = this.currentCard === this.id
            ? 'credit-card-selected elevation-16'
            : this.currentCard === undefined ? '' : 'credit-card-fade';
          return this.activeCard ? cardClass : cardClass + ' card-disable'
        }
        return this.activeCard ? '' : ' card-disable'
      },
      cardMenu() {
        this.menu = false;
        if (!this.menuActiveCard) {
          this.$emit('selectCard', undefined);
        }
        if ((this.activeCard !== this.menuActiveCard)) {
          this.hideCard();
          this.$emit('hideCard', this.id);
        }
        if ((this.defaultCard !== this.menuDefaultCard)) {
          this.setDefaultCard();
          this.$emit('defaultCard', this.id);
        }
        this.activeCard = this.menuActiveCard;
        this.defaultCard = this.menuDefaultCard;
      },
      selectCard() {
        let payload;
        if ( this.activeCard ) {
          if (this.selectEvent) {
            payload = this.id;
          } else {
            payload = undefined;
          }
        } else {
          payload = undefined;
        }

        this.$emit('selectCard', payload);
      },
      deleteCart(value) {
        if (value.question) {
          this.deleteModal = false;
          this.menu = false;
          this.removeCard()
        } else {
          this.deleteModal = false;
        }
      },
    },
    comments: {
      confirmDialog
    },
    mounted() {
      this.cardDescription = this.getPayService(this.pan || '51')
    }
  }
</script>

<style scoped lang="scss">
  .credit-card {
    background: rgba(0, 0, 0, 0.69);
    position: relative;
    cursor: pointer;
    transition: 0.2s scale ease;
    transform: scale(1);
    filter: none;

  }

  .card-row {
    .card-col:nth-child(even) > .credit-card {
      margin-left: auto;
    }

    .card-col:nth-child(odd) > .credit-card {
      margin-right: auto;
    }
  }

  .credit-card-selected {
    transition: 0.25s scale ease !important;
    transform: scale(1.1) !important;
  }

  .credit-card-fade {
    filter: blur(0.8px) !important;
  }

  .pay-logo {
    width: 60px;
    height: 50px;
    position: absolute;
    right: 12px;
    top: 0;
    border-radius: 0;
  }

  .card-number {
    font-family: "OcrA", sans-serif;
    position: absolute;
    bottom: 30px;
    width: 100%;
    padding-left: 12px;
  }

  .card-selected {
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 100%;
    height: 20px;
    text-transform: uppercase;
    background: #636161;
    color: #ffffff;
    text-align: right;
    padding-right: 17px;
    font-size: 0.81em;
    letter-spacing: 1px;
  }
  .card-default {
    position: absolute;
    bottom: 57px;
    right: 0;
    width: 100%;
    height: 20px;
    text-transform: lowercase;
    background: #efefef0a;
    color: #ffffff;
    text-align: left;
    padding-left: 13px;
    font-size: 0.76em;
    letter-spacing: 2px;
    font-weight: 100;
  }

  .card-title {
    width: 150px;
    height: 45px;
    position: absolute;
    left: 12px;
    top: 5px;
    border-radius: 0;

    span {
      padding-top: 10px;
      display: block;
      line-height: 13px;
    }
  }

  .no-select {
    user-select: none;
  }

  .card-button {
    position: relative;
    background: #f1f8fe;
    min-height: 120px;
    border: 0.9px solid #c1e6fe;
    box-shadow: none;
    border-radius: 7px;

    .button-title-wrapper {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
      width: 100%;
      text-align: center;
      color: #758692;
      letter-spacing: 1.1px;
      transition: .3s background-color ease;
    }

    &:hover {
      background: #ecf3f9;
      transition: .3s background-color ease;
    }
  }

  .card-button-group {
    position: absolute;
    /* width: 103px; */
    background: transparent;
    height: 57px;
    right: -2px;
    bottom: 2px;
    border-radius: 0;
  }

  .card-disable {
    filter: grayscale(1) !important;
    cursor: default !important;
  }
</style>
