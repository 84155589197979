import http from '@/lib/http'

const CARD_NEW_URL = '/card/new';
const CARD_ADD_URL = '/card/add';
const CARD_LIST_URL = '/card/list';
const CARD_REMOVE_URL = '/card/remove';
const CARD_HIDE_URL = '/card/hide';
const CARD_DEFAULT_URL = '/card/default';

export const CardAPI = {
  new(payload) {
    return http.post(CARD_NEW_URL, payload)
  },
  add() {
    return http.get(CARD_ADD_URL)
  },
  list() {
    return http.post(CARD_LIST_URL, {})
  },
  remove(payload) {
    return http.post(CARD_REMOVE_URL, payload)
  },
  hide(payload) {
    return http.post(CARD_HIDE_URL, payload)
  },
  setDefault(payload) {
    return http.post(CARD_DEFAULT_URL, payload)
  }
};
